import {
    addMonths,
    addYears,
    eachDayOfInterval,
    eachMonthOfInterval,
    eachWeekOfInterval,
    endOfMonth,
    endOfWeek,
    isAfter,
    isBefore,
    isEqual,
    set,
    setMonth,
    setYear,
    startOfMonth,
    startOfToday,
    startOfWeek,
    subMonths,
    subYears,
} from 'date-fns'
import { useCallback, useMemo, useState } from 'react'

const inRange = (date, min, max) => (
    (
        isEqual(date, min)
        || isAfter(date, min)
    ) && (
        isEqual(date, max)
        || isBefore(date, max)
    )
)

const clearTime = (date) => set(date, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0
})

const useCalendar = ({
    weekStartsOn = 0,
    viewing: initialViewing = new Date(),
    selected: initialSelected = [],
    numberOfMonths = 1,
} = {}) => {
    const [viewing, setViewing] = useState(initialViewing)

    const viewToday = useCallback(() => setViewing(startOfToday()), [setViewing])

    const viewMonth = useCallback((month, date) => (
        setViewing((v) => setMonth(date || v, month))
    ), [])

    const viewPreviousMonth = useCallback(() => setViewing((v) => subMonths(v, 1)), [])

    const viewNextMonth = useCallback(() => setViewing((v) => addMonths(v, 1)), [])

    const viewYear = useCallback((year) => setViewing((v) => setYear(v, year)), [])

    const viewPreviousYear = useCallback(() => setViewing((v) => subYears(v, 1)), [])

    const viewNextYear = useCallback(() => setViewing((v) => addYears(v, 1)), [])

    const [selected, setSelected] = useState(initialSelected)

    const clearSelected = () => setSelected([])

    const isSelected = useCallback((date) => (
        selected.findIndex((s) => isEqual(s, date)) > -1
    ), [selected])

    const select = useCallback((date, replaceExisting) => {
        if (replaceExisting) {
            setSelected(Array.isArray(date) ? date : [date])
        } else {
            setSelected((selectedItems) => (
                selectedItems.concat(Array.isArray(date) ? date : [date])
            ))
        }
    }, [])

    const deselect = useCallback((date) => (
        setSelected((selectedItems) => (
            Array.isArray(date)
                ? selectedItems.filter((s) => !date.map((d) => d.getTime()).includes(s.getTime()))
                : selectedItems.filter((s) => !isEqual(s, date))
        ))
    ), [])

    const toggle = useCallback((date, replaceExisting) => (
        isSelected(date) ? deselect(date) : select(date, replaceExisting)
    ), [deselect, isSelected, select],)

    const selectRange = useCallback((start, end, replaceExisting) => {
        if (replaceExisting) {
            setSelected(eachDayOfInterval({ start, end }))
        } else {
            setSelected((selectedItems) => selectedItems.concat(eachDayOfInterval({ start, end })))
        }
    }, [])

    const deselectRange = useCallback((start, end) => {
        setSelected((selectedItems) => (
            selectedItems.filter((s) => (
                !eachDayOfInterval({ start, end })
                    .map((d) => d.getTime())
                    .includes(s.getTime())
            ))
        ))
    }, [])

    const calendar = useMemo(
        () => (
            eachMonthOfInterval({
                start: startOfMonth(viewing),
                end: endOfMonth(addMonths(viewing, numberOfMonths - 1)),
            }).map((month) => (
                eachWeekOfInterval({
                    start: startOfMonth(month),
                    end: endOfMonth(month),
                }, { weekStartsOn }).map((week) => (
                    eachDayOfInterval({
                        start: startOfWeek(week, { weekStartsOn }),
                        end: endOfWeek(week, { weekStartsOn }),
                    })
                ))
            ))
        ),
        [viewing, weekStartsOn, numberOfMonths]
    )
    return {
        clearTime,
        inRange,
        viewing,
        setViewing,
        viewToday,
        viewMonth,
        viewPreviousMonth,
        viewNextMonth,
        viewYear,
        viewPreviousYear,
        viewNextYear,
        selected,
        setSelected,
        clearSelected,
        isSelected,
        select,
        deselect,
        toggle,
        selectRange,
        deselectRange,
        calendar,
    }
}

export default useCalendar
