/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react'
import clsx from 'clsx'
import ModalCloseButton from './ModalCloseButton'

const ModalSlideUpHeader = ({
    title,
    className,
    children,
    onClose,
    ...props
}) => (
    <div
        className={clsx(
            'absolute top-0 left-0 right-0 py-6 px-4 bg-white',
            className,
        )}
        {...props}
    >
        <h3 className="text-xs uppercase font-bold tracking-widest">
            {title}
        </h3>
        {children}
        {onClose && (
            <ModalCloseButton onClick={onClose} />
        )}
    </div>
)

export default ModalSlideUpHeader
