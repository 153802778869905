import clsx from 'clsx'
import { variant } from './index'
import * as constants from '@connections/constants'

const {
    BACKGROUND_WHITE,
    BACKGROUND_GRAY_LIGHT,
    BACKGROUND_GRAY,
    BACKGROUND_BLUE,
    BACKGROUND_BLACK,
    SECTION_SPACING_SMALLER,
    SECTION_SPACING_SMALL,
    SECTION_SPACING_MEDIUM,
    SECTION_SPACING_LARGE,
    CONTAINER_NONE,
    CONTAINER_EXTRA_SMALL,
    CONTAINER_MEDIUM,
    CONTAINER_SMALL,
    CONTAINER_DEFAULT,
    TEXT_WHITE,
    TEXT_BLACK,
    TEXT_PRIMARY,
    TEXT_BLUE,
} = constants

export const sectionContainerVariant = (
    value = CONTAINER_DEFAULT,
    otherwise,
    variants,
) => variant(value, {
    [CONTAINER_NONE]: '',
    [CONTAINER_EXTRA_SMALL]: 'container-xs',
    [CONTAINER_SMALL]: 'container-sm',
    [CONTAINER_MEDIUM]: 'container-md',
    [CONTAINER_DEFAULT]: 'container',
    ...variants,
}, otherwise)

export const sectionBackgroundVariant = (
    value = BACKGROUND_WHITE,
    otherwise,
    variants,
) => variant(value, {
    [BACKGROUND_WHITE]: '',
    [BACKGROUND_GRAY_LIGHT]: 'bg-gray-50',
    [BACKGROUND_GRAY]: 'bg-gray-100',
    [BACKGROUND_BLACK]: 'bg-black',
    [BACKGROUND_BLUE]: 'bg-blue',
    ...variants,
}, otherwise)

export const sectionSpacingVariant = (
    {
        spacing,
        spacingTop = null,
        spacingBottom = null,
    },
    otherwise,
    variants,
) => {
    const hasOverride = spacingTop || spacingBottom
    if (hasOverride) {
        return clsx(
            variant(spacingTop || spacing, {
                [SECTION_SPACING_SMALLER]: 'pt-8',
                [SECTION_SPACING_SMALL]: 'pt-12',
                [SECTION_SPACING_MEDIUM]: 'pt-24',
                [SECTION_SPACING_LARGE]: 'pt-36',
            }, otherwise),
            variant(spacingBottom || spacing, {
                [SECTION_SPACING_SMALLER]: 'pb-8',
                [SECTION_SPACING_SMALL]: 'pb-12',
                [SECTION_SPACING_MEDIUM]: 'pb-24',
                [SECTION_SPACING_LARGE]: 'pb-36',
            }, otherwise)
        )
    }
    return clsx(
        variant(spacing, {
            [SECTION_SPACING_SMALLER]: 'py-8',
            [SECTION_SPACING_SMALL]: 'py-12',
            [SECTION_SPACING_MEDIUM]: 'py-24',
            [SECTION_SPACING_LARGE]: 'py-36',
            ...variants,
        }, otherwise)
    )
}

export const sectionTextOnBackgroundVariant = (
    value,
    otherwise,
    variants,
) => variant(value, {
    [BACKGROUND_BLACK]: 'text-white',
    [BACKGROUND_BLUE]: 'text-white',
    ...variants,
}, otherwise)

export const textColorVariant = (
    value = TEXT_WHITE,
) => variant(value, {
    [TEXT_WHITE]: 'text-white',
    [TEXT_BLACK]: 'text-black',
    [TEXT_PRIMARY]: 'text-primary',
    [TEXT_BLUE]: 'text-blue',
})
