import React from 'react'
import { Trans } from '@lingui/react'
import clsx from 'clsx'
import {
    isEqual,
    isToday,
    isFirstDayOfMonth,
    isLastDayOfMonth,
    isSameMonth,
    isBefore,
} from 'date-fns'
import useFormatDate from '../hooks/useFormatDate'

const CalendarTitle = ({ day }) => {
    const format = useFormatDate()
    return (
        <h3 className="text-xs uppercase tracking-widest font-medium mb-6 pl-2">
            {format(day, 'MMMM yyyy')}
        </h3>
    )
}

const CalendarWeekDaysRow = ({ week }) => {
    const format = useFormatDate()
    return (
        <div className="grid grid-cols-7 gap-0 mb-1 text-xs uppercase tracking-widest font-medium text-gray-600">
            {week.map((day) => (
                <div
                    key={`${day}`}
                    className="flex items-center justify-center"
                >
                    {format(day, 'EEEEE')}
                </div>
            ))}
        </div>
    )
}

const CalendarDaysRow = ({ children }) => (
    <div className="grid grid-cols-7 gap-0 mb-1">
        {children}
    </div>
)

const CalendarDayLabel = ({
    color = 'blue',
    className,
    children
}) => (
    <div
        className={clsx(
            'absolute w-[calc(100%+2px)] -top-[5px] h-3 px-[4px] pt-[3px] rounded-t-md text-[7px] leading-[7px] font-semibold text-center uppercase truncate text-white ',
            color === 'blue' && 'bg-blue-bright',
            color === 'gray' && 'bg-gray-400',
            className,
        )}
    >
        {children}
    </div>
)

const CalendarDay = ({
    day,
    isDisabled,
    isSelected,
    isBetweenSelected,
    className,
    hasLabel,
    labelText,
    labelColor,
    labelClassName,
    isToday: isTodayProp,
    ...props
}) => {
    const format = useFormatDate()
    return (
        <button
            type="button"
            disabled={isDisabled}
            className={clsx(
                'relative flex items-center justify-center p-2 text-sm test-calendar-day',
                (!isDisabled && !isSelected) && 'hover:bg-gray-100',
                isDisabled && 'text-gray-100 cursor-not-allowed',
                !isTodayProp && 'border border-transparent',
                isTodayProp && 'border border-gray-400',
                isSelected && 'bg-blue-bright text-white rounded-md',
                !isBetweenSelected && 'rounded-md',
                isBetweenSelected && 'bg-gray-100',
                className,
            )}
            {...props}
        >
            {hasLabel && (
                <CalendarDayLabel
                    color={labelColor}
                    className={labelClassName}
                >
                    {labelText}
                </CalendarDayLabel>
            )}
            {format(day, 'dd')}
        </button>
    )
}

const Calendar = ({
    month,
    inRange,
    isSelected,
    dayIsDisabledCondition = (day) => isBefore(day, new Date()),
    selected,
    onClickDay,
    className,
    labelTextToday = <Trans id="Today" />,
    labelTextFirstSelected = <Trans id="Start" />,
    labelTextLastSelected = <Trans id="End" />,
}) => {
    const format = useFormatDate()
    const [firstWeek] = month
    const allDays = month.flatMap((week) => week)
    const firstDay = allDays.find((day) => isFirstDayOfMonth(day))
    const lastDay = allDays.find((day) => (
        isLastDayOfMonth(day)
        && isSameMonth(day, firstDay)
    ))
    return (
        <div className={clsx(className, 'w-full max-w-[300px] mx-auto')}>
            <CalendarTitle day={firstDay} />
            <CalendarWeekDaysRow week={firstWeek} />
            {month.map((week) => (
                <CalendarDaysRow key={`week-${week[0]}`}>
                    {week.map((day) => {
                        const isInRangeOfMonth = inRange(day, firstDay, lastDay)
                        if (!isInRangeOfMonth) {
                            return <div key={`day-${day}`} />
                        }
                        const dayIsSelected = isSelected(day)
                        const [firstSelected] = selected
                        const lastSelected = selected[selected.length - 1]
                        const isFirstSelected = dayIsSelected && isEqual(day, firstSelected)
                        const isLastSelected = dayIsSelected && isEqual(day, lastSelected)
                        const dayIsToday = isToday(day)
                        const dayIsBetweenSelected = (
                            inRange(day, firstSelected, lastSelected)
                            && !isFirstSelected
                            && !isLastSelected
                        )
                        const isSelectedDay = isFirstSelected || isLastSelected
                        const hasLabel = isSelectedDay || dayIsToday
                        let labelText = ''
                        let labelColor = 'blue'
                        if (dayIsToday) {
                            labelColor = 'gray'
                            labelText = labelTextToday
                        } else if (isFirstSelected) {
                            labelText = labelTextFirstSelected
                        } else if (isLastSelected) {
                            labelText = labelTextLastSelected
                        }
                        return (
                            <CalendarDay
                                key={`day-${day}`}
                                day={day}
                                isDisabled={dayIsDisabledCondition(day)}
                                isSelected={isSelectedDay}
                                isBetweenSelected={dayIsBetweenSelected}
                                isToday={dayIsToday}
                                hasLabel={hasLabel}
                                labelColor={labelColor}
                                labelText={labelText}
                                onClick={() => onClickDay(day)}
                                data-testid={`CalendarDate-${format(day, 'M-d')}`}
                            />
                        )
                    })}
                </CalendarDaysRow>
            ))}
        </div>
    )
}

export default Calendar
