import React from 'react'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import useKeyPress from '../hooks/useKeyPress'
import useBlockBodyScrolling from '../hooks/useBlockBodyScrolling'
import Portal from './Portal'
import ModalBackground from './ModalBackground'

const HidesBackgroundWhenBouncing = () => (
    <div className="absolute h-40 left-0 right-0 -bottom-40 bg-white" />
)

const ModalSlideUp = ({
    onClose,
    children,
    className,
    isFullScreen = false,
    ...props
}) => {
    useBlockBodyScrolling()
    useKeyPress(({ keyCode }) => {
        if (keyCode === 27) {
            onClose()
        }
    })

    return (
        <Portal querySelector="#modals">
            {!isFullScreen && (
                <ModalBackground onClick={onClose} />
            )}
            <motion.div
                role="dialog"
                tabIndex="-1"
                aria-modal="true"
                animate={{ y: 0 }}
                exit={{ y: '100%' }}
                initial={{ y: '100%' }}
                transition={{ duration: 0.4 }}
                className={clsx([
                    'z-modal fixed bottom-0  w-full bg-white py-6 px-4 overflow-hidden',
                    !isFullScreen && 'rounded-t-xl',
                    isFullScreen && 'top-0',
                    className,
                ])}
                {...props}
            >
                {children}
                <HidesBackgroundWhenBouncing />
            </motion.div>
        </Portal>
    )
}

export default ModalSlideUp
