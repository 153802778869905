import React, { useEffect, useRef } from 'react'
import clsx from 'clsx'
import {
    compareAsc,
    isFirstDayOfMonth,
} from 'date-fns'
import { useOnClickOutside } from '@wappla/react-hooks'
import { Trans } from '@lingui/react'
import usePressEscape from '../hooks/usePressEscape'
import useCalendar from '../hooks/useCalendar'
import DateButton from './DateButton'
import Calendar from './Calendar'
import Button from './Button'
import DateCalendarMonthButtons from './DateCalendarMonthButtons'

const DateCalendar = ({
    date,
    viewDate,
    format = 'EEEEEE dd MMM',
    className,
    inputClassName,
    numberOfMonthButtons = 7,
    dayIsDisabledCondition,
    onChange,
    onClickOutside = () => { },
    onPressEscape = () => { },
    testId,
}) => {
    const inputRef = useRef()
    const containerRef = useRef()
    useEffect(() => inputRef.current.focus(), [])
    useOnClickOutside(containerRef, onClickOutside)
    usePressEscape(onPressEscape)
    const {
        calendar,
        deselect,
        inRange,
        isSelected,
        select,
        selected,
        selectRange,
        viewing,
        viewMonth,
        viewNextMonth,
        viewPreviousMonth,
        viewToday,
        clearSelected,
    } = useCalendar({
        viewing: date || viewDate,
        weekStartsOn: 1,
        numberOfMonths: 1,
        selected: [date]
    })
    const selectedSorted = selected.sort((a, b) => compareAsc(a, b))
    const [month] = calendar
    const allDays = month.flatMap((week) => week)
    const firstDay = allDays.find((day) => isFirstDayOfMonth(day))
    const [firstSelected = null] = selectedSorted
    useEffect(() => {
        onChange(firstSelected)
    }, [firstSelected])
    return (
        <div
            ref={containerRef}
            className={clsx(
                'bg-white',
                className
            )}
            data-testid={testId}
        >
            <div className="lg:flex lg:justify-between">
                <DateButton
                    ref={inputRef}
                    date={date}
                    format={format}
                    className={inputClassName}
                    placeholder={<Trans id="Date" />}
                />
                <div className="flex justify-center">
                    <Button
                        variant="link"
                        className="text-blue-bright"
                        iconBeforeName="calendar"
                        onClick={() => viewToday()}
                    >
                        <Trans id="Today" />
                    </Button>
                    <Button
                        variant="link"
                        className="text-blue-bright"
                        iconBeforeName="rotate"
                        onClick={() => clearSelected()}
                    >
                        <Trans id="Reset" />
                    </Button>
                </div>
            </div>
            <div
                className={clsx(
                    'mt-4 w-full',
                )}
            >
                <DateCalendarMonthButtons
                    numberOfMonths={numberOfMonthButtons}
                    firstDayFirstMonth={firstDay}
                    onClickMonth={(monthIndex, monthDate) => viewMonth(monthIndex, monthDate)}
                    onClickPreviousMonth={() => viewPreviousMonth()}
                    onClickNextMonth={() => viewNextMonth()}
                />
                <Calendar
                    className="mb-8"
                    month={month}
                    onDeselectDay={(day) => deselect(day)}
                    onSelectDay={(day) => select(day)}
                    inRange={inRange}
                    isSelected={isSelected}
                    selected={selectedSorted}
                    selectRange={selectRange}
                    dayIsDisabledCondition={dayIsDisabledCondition}
                    viewing={viewing}
                    viewNextMonth={viewNextMonth}
                    viewPreviousMonth={viewPreviousMonth}
                    viewToday={viewToday}
                    onClickDay={(day) => {
                        clearSelected()
                        select(day)
                    }}
                />
            </div>
        </div>
    )
}

export default DateCalendar
