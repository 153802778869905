import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import Button from './Button'
import DateCalendar from './DateCalendar'
import ModalSlideUp from './ModalSlideUp'
import ModalSlideUpFooter from './ModalSlideUpFooter'
import ModalSlideUpHeader from './ModalSlideUpHeader'
import ModalSlideUpContent from './ModalSlideUpContent'

const DateCalendarMobileModal = ({
    title,
    initialDate,
    onClose,
    onConfirm,
    dayIsDisabledCondition,
    viewDate,
    ...props
}) => {
    const [selectedDate, setSelectedDate] = useState(initialDate)
    return (
        <ModalSlideUp
            isFullScreen
            onClose={onClose}
            {...props}
        >
            <ModalSlideUpHeader
                title={title}
                onClose={onClose}
            />
            <ModalSlideUpContent>
                <DateCalendar
                    className="pt-4"
                    inputClassName="text-left"
                    date={selectedDate}
                    viewDate={viewDate}
                    numberOfMonthButtons={5}
                    onChange={(day) => setSelectedDate(day)}
                    dayIsDisabledCondition={dayIsDisabledCondition}
                />
            </ModalSlideUpContent>
            <ModalSlideUpFooter className="flex">
                <Button
                    className="mr-4 w-full"
                    variant="blue-outlined"
                    onClick={onClose}
                >
                    <Trans id="Close" />
                </Button>
                <Button
                    className="w-full"
                    variant="blue-filled"
                    iconAfterName="arrow-right"
                    onClick={() => onConfirm(selectedDate)}
                    data-testid="DateCalendarMobileModalConfirm"
                >
                    <Trans id="Confirm" />
                </Button>
            </ModalSlideUpFooter>
        </ModalSlideUp>
    )
}

export default DateCalendarMobileModal
