import React from 'react'
import clsx from 'clsx'
import Checkbox from './Checkbox'

const CheckboxInput = ({
    id,
    className,
    children,
    disabled,
    ...props
}) => (
    <label
        htmlFor={id}
        className={clsx([
            'inline-flex items-start cursor-pointer',
            disabled && 'cursor-not-allowed',
            className,
        ])}
    >
        <Checkbox
            id={id}
            className="mr-2"
            disabled={disabled}
            {...props}
        />
        {children}
    </label>
)

export default CheckboxInput
