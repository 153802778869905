import React, { forwardRef } from 'react'
import InputButton from './InputButton'
import useFormatDate from '../hooks/useFormatDate'

const DateButton = forwardRef(({
    date,
    testId,
    onClick,
    hasError,
    disabled,
    className,
    placeholder,
    format = 'EEEEEE dd MMM',
}, ref) => {
    const formatDate = useFormatDate()
    return (
        <InputButton
            ref={ref}
            onClick={onClick}
            disabled={disabled}
            hasError={hasError}
            data-testid={testId}
            className={className}
            isPlaceholder={!date}
        >
            {(date
                ? formatDate(date, format)
                : placeholder
            )}
        </InputButton>
    )
})

export default DateButton
