/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react'
import clsx from 'clsx'

const ModalSlideUpFooter = ({
    className,
    children,
    ...props
}) => (
    <div
        className={clsx(
            'absolute bottom-0 left-0 right-0 p-4 bg-white',
            className,
        )}
        {...props}
    >
        {children}
    </div>
)

export default ModalSlideUpFooter
