import React, { forwardRef } from 'react'
import clsx from 'clsx'
import { variant as createVariant } from '../../util/index'

export const VARIANT_INLINE = 'inline'
export const VARIANT_DEFAULT = 'default'

const Select = forwardRef(({
    value,
    className,
    isLoading,
    hasError = false,
    variant = VARIANT_DEFAULT,
    ...props
}, ref) => (
    <div className="relative">
        {isLoading && (
            <div className="absolute skeleton w-full h-full" />
        )}
        <select
            ref={ref}
            value={value}
            className={clsx(
                'disabled:bg-gray-50 disabled:cursor-not-allowed disabled:hover:border-gray-100',
                value ? 'text-black' : 'text-gray-400',
                hasError ? 'border-primary' : 'border-gray-100 hover:border-gray-300',
                createVariant(variant, {
                    [VARIANT_DEFAULT]: 'border py-3 pl-4 pr-8 w-full leading-[22px] rounded text-sm',
                    [VARIANT_INLINE]: 'border-none text-sm font-bold p-0 pr-8 -mr-2.5',
                }),
                className
            )}
            {...props}
        />
    </div>
))

export default Select
