import React from 'react'
import clsx from 'clsx'

const ModalSlideUpContent = ({
    className,
    children,
    ...props
}) => (
    <div
        className={clsx(
            'absolute top-16 bottom-20 left-0 right-0 pt-1 px-4 overflow-x-hidden overflow-y-scroll',
            className,
        )}
        {...props}
    >
        {children}
    </div>
)

export default ModalSlideUpContent
