import React from 'react'

const FormGroup = ({
    label,
    htmlFor,
    description = null,
    isRequired = false,
    children,
    className,
}) => (
    <label className={className} htmlFor={htmlFor}>
        <span className="block mb-2 text-sm text-left">
            {label}
            {typeof label !== 'undefined' && isRequired === true && (
                <span className="ml-2 text-primary">*</span>
            )}
        </span>
        {children}
        {description && (
            <p className="mt-1 text-sm text-gray-400" id={htmlFor}>
                {description}
            </p>
        )}
    </label>
)

export default FormGroup
