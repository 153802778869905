import React, { forwardRef } from 'react'
import clsx from 'clsx'

const InputButton = forwardRef(({
    hasError,
    className,
    children,
    isPlaceholder,
    ...props
}, ref) => (
    <button
        ref={ref}
        type="button"
        className={clsx([
            'border py-3 px-4 w-full leading-[22px] rounded text-sm text-left focus:outline-default-input',
            !hasError && 'border-gray-100',
            hasError && 'border-primary',
            isPlaceholder && 'text-gray-200',
            className,
        ])}
        {...props}
    >
        {children}
    </button>
))

export default InputButton
