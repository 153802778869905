import React from 'react'
import clsx from 'clsx'

const TimeSelect = ({
    className,
    ...props
}) => (
    <select
        className={clsx([
            'px-4 w-full leading-[22px] text-sm placeholder-gray-200 text-black',
            'py-[11px]', // this intentional
            className,
        ])}
        {...props}
    />
)

export default TimeSelect
