import React from 'react'
import clsx from 'clsx'
import Radio from './Radio'

const RadioInput = ({
    id,
    className,
    children,
    disabled,
    ...props
}) => (
    <label
        htmlFor={id}
        className={clsx([
            'inline-flex items-start cursor-pointer',
            disabled && 'text-gray-300 cursor-not-allowed',
            !disabled && 'text-black',
            className,
        ])}
    >
        <Radio
            className="mr-2"
            disabled={disabled}
            id={id}
            {...props}
        />
        {children}
    </label>
)

export default RadioInput
