import React from 'react'
import clsx from 'clsx'

const Checkbox = ({
    className,
    hasError,
    value,
    ...props
}) => (
    <input
        type="checkbox"
        checked={value}
        className={(clsx(
            'h-4 w-4 text-blue-bright border-gray-200 rounded cursor-pointer',
            'disabled:opacity-40',
            className,
        ))}
        {...props}
    />
)

export default Checkbox
