import React from 'react'
import clsx from 'clsx'

const Radio = React.forwardRef(({
    className,
    hasError,
    value,
    ...props
}, ref) => (
    <input
        type="radio"
        checked={value}
        className={(clsx(
            'h-4 w-4 cursor-pointer text-blue-bright border-gray-200',
            'disabled:opacity-40 disabled:cursor-not-allowed',
            className,
        ))}
        ref={ref}
        {...props}
    />
))

export default Radio
